import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TermsAcceptComponent } from './terms-accept/terms-accept.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('src/app/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'subscription',
    loadChildren: () =>
      import('src/app/subscription/subscription.module').then(
        (m) => m.SubscriptionModule
      ),
  },
  {
    path: 'campaing',
    loadChildren: () =>
      import('src/app/campaing/campaing.module').then((m) => m.CampaingModule),
  },
  {
    path: 'executors',
    loadChildren: () =>
      import('src/app/executors/executors.module').then(
        (m) => m.ExecutorsModule
      ),
  },
  {
    path: 'beneficiaries',
    loadChildren: () =>
      import('src/app/beneficiaries/beneficiaries.module').then(
        (m) => m.BeneficiariesModule
      ),
  },
  {
    path: 'terms-accept',
    component: TermsAcceptComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
