import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-accept',
  templateUrl: './terms-accept.component.html',
  styleUrls: ['./terms-accept.component.scss']
})
export class TermsAcceptComponent {

}
