<!-- TERMOS DE CONSENTIMENTO -->
<div class="terms-container">
  <h1>
    TERMO DE CONSENTIMENTO PARA ACESSO, USO, GUARDA E COMPARTILHAMENTO DE DADOS,
    IMAGENS, VOZ E ARQUIVOS PESSOAIS
  </h1>
  <p>
    <strong>De acordo com a LEI GERAL DE PROTEÇÃO DE DADOS (13.709/18)</strong>
  </p>

  <h2>1. DA AUTORIZAÇÃO</h2>
  <p>
    <strong>1.1</strong> Assinando o presente termo, o Titular consente e
    concorda que a empresa KRESKO TECH LTDA, pessoa jurídica de direito privado,
    inscrita no CNPJ sob o nº 02.884.675/0001-76, estabelecida na Rua José Lobo
    nº 78, Sala 2, Bairro Jardim Maria Adelaide, Cidade de São Bernardo do Campo
    - SP, com endereço eletrônico: info&#64;kresko.tech, através do produto
    denominado GUARDA DIGITAL, doravante denominada Controladora, tome decisões
    referentes ao tratamento de seus dados pessoais, bem como realize o acesso,
    reprodução, transmissão, distribuição, processamento, arquivamento,
    armazenamento, eliminação, avaliação ou controle da informação, modificação,
    comunicação, transferência, difusão ou extração.
  </p>

  <h2>2. DAS INFORMAÇÕES E CONSIDERAÇÕES GERAIS</h2>
  <p>
    <strong>2.1</strong> Bem-vindo(a) ao Sistema do Guarda Digital! Este é uma
    plataforma que tem como finalidade servir como um cofre digital pessoal,
    sendo algumas de suas funcionalidades o armazenamento de dados pessoais,
    fotos, vídeos, documentos, entre outros. Além disso, permite o
    compartilhamento e interação desse material com outras pessoas da sua
    escolha, na forma que desejar.
  </p>
  <p>
    <strong>2.2</strong> Bem-vindo(a) ao Sistema do Guarda Digital! Este é uma
    plataforma que tem como finalidade servir como um cofre digital pessoal,
    sendo algumas de suas funcionalidades o armazenamento de dados pessoais,
    fotos, vídeos, documentos, entre outros. Além disso, permite o
    compartilhamento e interação desse material com outras pessoas da sua
    escolha, na forma que desejar.
  </p>
  <p>
    <strong>2.3</strong> O conteúdo, informações, dados, mídias e demais
    documentos postados pelo USUÁRIO são de sua inteira responsabilidade,
    devendo agir de forma lícita e idônea, não podendo as entidades parceiras e
    mantenedoras do SISTEMA serem responsabilizados por quaisquer fatos
    decorrentes da postagem desse conteúdo, de acordo com os termos do Marco
    Civil da Internet (art. 19 da Lei nº 12.965, de 2014).
  </p>
  <p>
    <strong>2.4</strong> Em sintonia com os termos da Lei n.º 13.709, de 14 de
    agosto de 2018, Lei Geral de Proteção de Dados Pessoais (LGPD), é
    obrigatório que as partes envolvidas sejam informadas sobre o cadastro,
    registro e/ou manutenção de dados pessoais. Neste sentido, esclarecemos que
    todos os dados, pessoais ou não, são tratados com sigilo e respeito à
    intimidade e pessoalidade.
  </p>
  <p>
    <strong>2.5</strong> Nos reservamos o direito de alterar, adicionar ou
    atualizar quaisquer termos ou condições contidas no presente Termo a
    qualquer momento, sendo que todas as eventuais alterações serão sempre
    comunicadas aos usuários e utilizadores do Sistema da Guarda Digital.
  </p>
  <p>
    <strong>2.6</strong> Ao fim desse documento, escolhendo a opção “CONCORDO
    COM OS TERMOS DE USO E CONDIÇÕES”, você declara expressamente sua
    concordância com os parâmetros para utilização da plataforma, bem como se
    responsabiliza por todo e qualquer acesso que vier a ser feito com o seu
    login e senha.
  </p>

  <h2>3. DOS DADOS PESSOAIS</h2>
  <p>
    <strong>3.1</strong> A Controladora fica autorizada a tomar decisões
    referentes ao tratamento e uso dos seguintes dados pessoais do Titular:
  </p>
  <ul>
    <li>a. Nome completo;</li>
    <li>b. Número de telefone;</li>
    <li>c. Endereço de e-mail;</li>
    <li>d. Endereço pessoal;</li>
    <li>e. Documentos pessoais;</li>
    <li>f. Imagens, fotos e vídeos;</li>
    <li>g. Arquivos pessoais;</li>
    <li>h. Dados sensíveis de saúde, como exames, diagnósticos, etc.;</li>
    <li>i. Disposição de vontades, como testamento;</li>
    <li>j. Outras informações fornecidas pelo usuário.</li>
  </ul>

  <h2>4. FINALIDADE DO TRATAMENTO DE DADOS</h2>
  <p>
    <strong>4.1</strong> O tratamento dos dados e documentos pessoais listados
    neste termo tem as seguintes finalidades:
  </p>
  <ul>
    <li>
      a. Possibilitar que a Controladora identifique e entre em contato com o
      Titular para fins de relacionamento comercial;
    </li>
    <li>
      b. Possibilitar que a Controladora envie ou forneça ao Titular seus
      produtos e serviços, de forma remunerada ou gratuita;
    </li>
    <li>
      c. Possibilitar que a Controladora guarde, use e armazene seus dados para
      o objetivo proposto do produto;
    </li>
    <li>
      d. Possibilitar o compartilhamento dos dados selecionados com quem o
      Titular autorizar o acesso, visando o fim a que se destina.
    </li>
  </ul>

  <h2>5. COMPARTILHAMENTO DE DADOS COM OUTROS SETORES DA CONTROLADORA</h2>
  <p>
    <strong>5.1</strong> A Controladora fica autorizada a compartilhar os dados
    pessoais do Titular com outros agentes de tratamento de dados, caso seja
    necessário para as finalidades listadas neste termo, observados os
    princípios e as garantas estabelecidas pela Lei nº 13.709/2018.
  </p>

  <h2>6. COMPARTILHAMENTO DE DADOS COM TERCEIROS</h2>
  <p>
    <strong>6.1</strong> A Controladora fica autorizada a compartilhar os dados
    pessoais do Titular com empresas terceirizadas que prestam serviços a ela e
    que são relacionados ao cumprimento da atividade-fim desta autorização.
  </p>
  <p>
    <strong>6.2</strong> Os dados pessoais que o usuário autorizar o
    compartilhamento com os beneficiários por ele integrados na plataforma da
    Guarda Digital serão de inteira responsabilidade do usuário, podendo
    conceder autorizações de acesso específicas, conforme seu interesse, devendo
    se atentar com quem irá compartilhar, principalmente no caso dos dados
    sensíveis, como os de sua saúde, para que não haja o vazamento de
    informações pessoais, evitando qualquer tipo de prejuízo.
  </p>
  <p>
    <strong>6.3</strong> O usuário poderá rever sobre as autorizações e acessos
    concedidos aos terceiros-beneficiários a qualquer momento, podendo alterar
    parcialmente ou revogar no todo.
  </p>

  <h2>7. SEGURANÇA DOS DADOS</h2>
  <p>
    <strong>7.1</strong> A Controladora responsabiliza-se pela manutenção de
    medidas de segurança, técnicas e administrativas aptas a proteger os dados
    pessoais de acessos não autorizados e de situações acidentais ou ilícitas de
    destruição, perda, alteração, comunicação ou qualquer forma de tratamento
    inadequado, exceto em caso fortuito e de força maior.
  </p>
  <p>
    <strong>7.2</strong> Em conformidade ao art. 48 da Lei nº 13.709/2018, o
    Controlador comunicará ao Titular e à Autoridade Nacional de Proteção de
    Dados (ANPD) a ocorrência de incidente de segurança que possa acarretar
    risco ou dano relevante ao Titular.
  </p>

  <h2>8. TÉRMINO DO TRATAMENTO DOS DADOS</h2>
  <p>
    <strong>8.1</strong> A Controladora poderá manter e tratar os dados pessoais
    do Titular durante todo o período em que eles forem pertinentes ao alcance
    das finalidades listadas neste termo. Dados pessoais anonimizados, sem
    possibilidade de associação ao indivíduo, poderão ser mantidos por período
    indefinido.
  </p>
  <p>
    <strong>8.2</strong> O Titular poderá solicitar via e-mail
    info&#64;kresko.tech, ou correspondência à Controladora, a qualquer momento,
    que sejam eliminados os dados pessoais não anonimizados.
  </p>
  <p>
    <strong>8.3</strong> O Titular fica ciente de que poderá ser inviável à
    Controladora continuar o fornecimento de produtos ou serviços ao Titular a
    partir da eliminação dos dados pessoais.
  </p>

  <h2>9. DIREITOS DO TITULAR</h2>
  <p>
    <strong>9.1</strong> O Titular tem direito a obter da Controladora, em
    relação aos dados por ela tratados, a qualquer momento e mediante
    requisição:
  </p>
  <ul>
    <li>a. Confirmação da existência de tratamento;</li>
    <li>b. Acesso aos dados;</li>
    <li>c. Correção de dados incompletos, inexatos ou desatualizados;</li>
    <li>d. Anonimização, bloqueio ou eliminação de dados desnecessários;</li>
    <li>
      e. Eliminação dos dados pessoais tratados com o consentimento do titular;
    </li>
    <li>
      f. Informação das entidades públicas e privadas com as quais o controlador
      realizou uso compartilhado de dados;
    </li>
    <li>g. Informação sobre a possibilidade de não fornecer consentimento;</li>
    <li>h. Revogação do consentimento, nos termos da Lei nº 13.709/2018.</li>
  </ul>

  <h2>10. DISPOSIÇÕES GERAIS</h2>
  <p>
    <strong>10.1</strong> O USUÁRIO entende que deverá agir com idoneidade e com
    a devida cautela quanto a postagem de conteúdo e o seu compartilhamento,
    <strong
      >declarando para os devidos fins e efeitos, que responsabiliza-se
      integralmente pela adequada utilização do sistema e dados a que tiver
      acesso, estando ciente da possibilidade de responder civil, criminal e
      administrativamente pelos danos morais ou materiais decorrentes da má
      utilização, reprodução ou divulgação indevida de dados e
      documentos.</strong
    >
  </p>
</div>

<!-- TERMOS DE USO -->
<div class="terms-container">
  <h1>
    TERMO DE USO, RESPONSABILIDADE E ACESSO AOS DADOS CONTIDOS NO GUARDA DIGITAL
    PELO USUÁRIO-BENEFICIÁRIO
  </h1>
  <p>
    <strong>
      Acesso de Informações, Documentos, Imagem e Som de Usuário-Cliente do
      Sistema Guarda Digital</strong
    >
  </p>
  <h2><strong>I. DA AUTORIZAÇÃO</strong></h2>
  <p>
    Este documento conjuntamente com o TERMO DE CONFIDENCIALIDADE deve ter o
    aceite do(a) usuário(a) que vier a se cadastrar na plataforma, o qual terá
    que concordar integralmente com os termos para acesso e utilização do
    sistema. Além disso, esse termo tem como finalidade conceder o acesso após a
    manifestação livre e inequívoca de vontade pela qual o usuário Sr./Sra.
    ___________________________________________ que aderiu o produto Guarda
    Digital autorizou o compartilhamento dos seus dados pessoais, podendo ser
    fotos, vídeos, informações e documentos, exclusivamente com a pessoa que
    assina esse termo, aqui nomeada como usuário-beneficiário, o qual entende
    que possui responsabilidade e dever de respeito e proteção dos dados e
    informações a que tiver acesso, não podendo de nenhuma forma divulgar os
    dados ou conceder acesso a terceiro, sob pena de ser responsabilizado por
    todos os danos causados, tanto na esfera cível e penal, decorrentes do
    vazamento e divulgação de qualquer dado obtido através da plataforma, sendo
    ainda o seu dever, prezar pela veracidade e idoneidade das informações
    cadastradas na Sistema da Guarda Digital.
  </p>
  <p>
    Assinando o presente termo, o Titular-Beneficiário consente e concorda que a
    empresa KRESKO TECH LTDA, pessoa jurídica de direito privado, inscrita no
    CNPJ sob o nº 02.884.675/0001-76, estabelecida na Rua José Lobo nº 78, Sala
    2, Bairro Jardim Maria Adelaide, Cidade de São Bernardo do Campo - SP, com
    endereço eletrônico: info&#64;kresko.tech, através do produto denominado
    GUARDA DIGITAL, doravante denominada Controladora, concedeu o acesso aos
    dados referidos mediante concordância com os termos descritos.
  </p>

  <h2><strong>II. INFORMAÇÕES E CONSIDERAÇÕES GERAIS</strong></h2>
  <p>
    Bem-vindo(a) ao Sistema do Guarda Digital! Este é uma plataforma que tem
    como finalidade servir como um cofre digital pessoal, sendo algumas das suas
    funcionalidades o armazenamento de dados pessoais, fotos, vídeos,
    documentos, dados de saúde, entre outros. Além disso, permite o
    compartilhamento e interação desse material com outras pessoas da sua
    escolha, na forma que desejar.
  </p>
  <p>
    A utilização está condicionada a sua aceitação e ao cumprimento deste Termo
    de Uso. Assim, pedimos que leia atentamente este documento, o qual foi
    elaborado buscando deixar claro e objetivo as condições, obrigações,
    responsabilidades e requisitos para que possamos seguir ampliando nosso
    Sistema de forma a melhor atender com excelência e segurança os usuários e
    seus beneficiários.
  </p>
  <p>
    Em sintonia com os termos da Lei n.º 13.709, de 14 de agosto de 2018, Lei
    Geral de Proteção de Dados Pessoais (LGPD), é obrigatório que as partes
    envolvidas sejam informadas sobre o cadastro, registro e/ou manutenção de
    dados pessoais. Neste sentido, esclarecemos que todos os dados, pessoais ou
    não, são tratados com sigilo e respeito à intimidade e pessoalidade.
  </p>

  <h2><strong>III. DA UTILIZAÇÃO DO SISTEMA</strong></h2>
  <p>
    A utilização do Sistema do Guarda Digital pelos usuários deve prezar pelo
    respeito, sigilo, privacidade, veracidade, idoneidade e interesses
    individuais, não podendo o Sistema do Guarda Digital ser utilizado para fins
    ilícitos ou estranhos a finalidade a que foi destinado.
  </p>
  <p>
    As informações compartilhadas deverão ser acessadas exclusivamente pelo
    USUÁRIO-BENEFICIÁRIO, devendo se atentar que o descumprimento dessa e das
    demais cláusulas poderá sujeitá-lo na responsabilização civil e penal
    quantos aos prejuízos gerados.
  </p>

  <h2><strong>IV. DA CONFIDENCIALIDADE E SEGURANÇA DE SENHA</strong></h2>
  <p>
    O USUÁRIO-BENEFICIÁRIO responsabiliza-se expressa e exclusivamente em manter
    a integralidade, sigilo e privacidade de todas as informações, documentos,
    vídeos, imagens e dados a que tiver acesso, bem como declara tomar todas as
    medidas possíveis para guardar e proteger o sigilo dos dados compartilhados,
    especialmente em:
  </p>
  <ul>
    <li>
      Não compartilhar senhas e evitar registrar as senhas em locais públicos ou
      onde outras pessoas poderão ter acesso;
    </li>
    <li>
      Selecionar senhas de boa qualidade, evitando o uso de senhas muito curtas
      ou muito longas, que tenha que anotar em algum lugar;
    </li>
    <li>
      Alterar a senha sempre que existir qualquer indicação de possível
      comprometimento do Sistema ou da própria senha;
    </li>
    <li>Evitar reutilizar as mesmas senhas;</li>
    <li>Assegurar que sua senha não seja acessada por terceiros;</li>
    <li>
      Não baixar os arquivos sem expressa concordância do usuário-cliente;
    </li>
  </ul>

  <h2><strong>V. DA VIOLAÇÃO DOS DADOS</strong></h2>
  <p>
    A violação refere-se a qualquer uso não autorizado, acesso, divulgação ou
    perda de informações confidenciais ou sensíveis. Ou seja, é quando
    informações que deveriam estar protegidas são comprometidas e acessadas por
    indivíduos sem a autorização dos donos desses dados.
  </p>

  <h2><strong>VI. DA RESPONSABILIDADE CIVIL</strong></h2>
  <p>
    O USUÁRIO-BENEFICIÁRIO compromete-se a não divulgar ou repassar o
    conhecimento das informações a que tiver acesso, incluindo fotos, vídeos,
    documentos, responsabilizando-se pelos vazamentos ocorridos pelos acessos
    através do seu login, que constitui afronta aos direitos fundamentais da
    pessoa, em especial à preservação da intimidade, e assim, se obrigando a
    ressarcir a ocorrência de qualquer dano material ou moral decorrentes dessa
    violação, além da responsabilização penal cabível ao caso.
  </p>

  <h2><strong>VII. DA RESPONSABILIDADE PENAL</strong></h2>
  <p>
    Divulgar é dar conhecimento a alguém ou tornar público. A finalidade do tipo
    penal é impedir que uma pessoa, legítima destinatária de um documento, que
    contenha um conteúdo confidencial (segredo), possa transmiti-lo a terceiros,
    causando dano a alguém.
  </p>
  <p>
    Art. 153 - Divulgar alguém, sem justa causa, conteúdo de documento
    particular ou de correspondência confidencial, de que é destinatário ou
    detentor, e cuja divulgação possa produzir dano a outrem:
    <br />
    Pena - detenção, de um a seis meses, ou multa, de trezentos mil réis a dois
    contos de réis. (Vide Lei nº 7.209, de 1984)
  </p>

  <h2><strong>VIII. PRAZO DE DURAÇÃO</strong></h2>
  <p>
    A vigência das obrigações oriundas desse termo, principalmente quanto a
    confidencialidade e sigilo de todos os dados e informações a que tiver
    acesso através da plataforma do Guarda Digital terá a validade enquanto a
    informação não for tornada de conhecimento público por qualquer outra
    pessoa, ou mediante autorização escrita, concedida diretamente pelas partes
    interessadas neste termo.
  </p>
  <p>
    Assim, declaro para os devidos fins e efeitos, que me responsabilizo
    integralmente pela utilização do sistema e pelos dados, informações e
    documentos inseridos por mim, estando ciente de que devo agir de forma
    respeitosa, idônea e lícita, tomando os devidos cuidados sobre o direito à
    privacidade e compartilhamento com terceiros, podendo responder por danos
    ocorridos pela violação de preceitos legais.
  </p>
</div>

<!-- TERMOS DE NOMEAÇÃO E ACEITAÇÃO -->
<h1>
  TERMO DE NOMEAÇÃO E ACEITE DO ENCARGO DE EXECUTOR-GUARDIÃO DO USUÁRIO (NOME)
</h1>
<div class="terms-container">
  <h2><strong>DOS CONCEITOS LEGAIS E ENCARGO</strong></h2>
  <p>
    A principal função do Executor-Guardião, ou também conhecido como
    testamenteiro, é dar cumprimento as vontades normalmente deixadas através de
    testamento e defender sua validade, bem como poderá desempenhar possíveis
    atribuições que delegue o testador.
  </p>
  <p>
    Pela nossa legislação, temos os artigos 1.980, 1.981 e 1.982 do Código Civil
    que regulamentam o assunto, vejamos abaixo:
  </p>
  <p>
    <strong>Art. 1.980</strong> - O testamenteiro é obrigado a cumprir as
    disposições testamentárias, no prazo marcado pelo testador, e a dar contas
    do que recebeu e despendeu, subsistindo sua responsabilidade enquanto durar
    a execução do testamento.
  </p>
  <p>
    <strong>Art. 1.981</strong> - Compete ao testamenteiro, com ou sem o
    concurso do inventariante e dos herdeiros instituídos, defender a validade
    do testamento.
  </p>
  <p>
    <strong>Art. 1.982</strong> - Além das atribuições exaradas nos artigos
    antecedentes, terá o testamenteiro as que lhe conferir o testador, nos
    limites da lei.
  </p>
  <p>
    As atribuições do testamenteiro são: fiscalizar o cumprimento das
    disposições testamentárias; cumprir as obrigações do testamento; pugnar pela
    sua validade; defender a posse dos bens da herança; bem como requerer ao
    juiz que lhe conceda os meios necessários para cumprir as disposições
    testamentárias. Atribuições previstas no art. 1137 do Código de Processo
    Civil.
  </p>
  <p>
    Importante ressaltar que poderá ser nomeado mais de um Executor-Guardião
    para o cumprimento das disposições de última vontade. (<strong
      >art. 1976</strong
    >
    do Código Civil).
  </p>

  <h2><strong>PRAZO</strong></h2>
  <p>
    Importante ressaltar que o Executor-Guardião terá sua incumbência até o
    efetivo cumprimento do testamento e demais vontades deixadas, devendo ainda,
    prestar contas de tudo o que for feito até que seja finalizado legalmente
    sua responsabilidade.
  </p>
  <p>
    <strong>Art. 1.980</strong> - O testamenteiro é obrigado a cumprir as
    disposições testamentárias, no prazo marcado pelo testador, e a dar contas
    do que recebeu e despendeu, subsistindo sua responsabilidade enquanto durar
    a execução do testamento.
  </p>

  <h2><strong>REMUNERAÇÃO</strong></h2>
  <p>
    O Executor-Guardião que cumprir integralmente seu encargo, sem qualquer tipo
    de contestação, deverá receber um tipo de remuneração por seu serviço,
    vejamos o que o Código Civil prevê nessa situação.
  </p>
  <p>
    <strong>Art. 1.987</strong> - Salvo disposição testamentária em contrário, o
    testamenteiro, que não seja herdeiro ou legatário, terá direito a um prêmio,
    que, se o testador não o houver fixado, será de um a cinco por cento,
    arbitrado pelo juiz, sobre a herança líquida, conforme a importância dela e
    maior ou menor dificuldade na execução do testamento.
  </p>

  <h2><strong>TESTAMENTO VITAL</strong></h2>
  <p>
    O documento DAV (Diretivas Antecipadas de Vontade), também conhecida como
    “testamento vital”, é um instrumento que permite ao paciente,
    antecipadamente, expressar sua vontade quanto às diretrizes de um tratamento
    médico futuro, caso fique impossibilitado de manifestar sua vontade em
    virtude de acidente ou doença grave. Por exemplo, por esse documento é
    possível determinar que a pessoa não deseja se submeter a tratamento para
    prolongamento da vida de modo artificial, às custas de sofrimento, ou ainda,
    deixar claro que se recusa a receber transfusão de sangue em caso de
    acidente ou cirurgia, não se limitando somente a essas situações.
  </p>
  <p>
    Dessa forma, essa é outra atribuição que o Executor-Guardião poderá ter,
    qual seja, no cumprimento de decisões médicas ainda em vida do usuário,
    devendo seguir suas determinações contidas nessas disposições de vontades,
    no caso de sua incapacidade.
  </p>

  <h2><strong>DA REVOGAÇÃO</strong></h2>
  <p>
    Esse termo de nomeação e encargo poderá ser revogado a qualquer tempo pelo
    usuário-titular do testamento (testador), não havendo nenhuma obrigação
    decorrente dessa revogação.
  </p>

  <h2><strong>DA CIÊNCIA DOS TERMOS E DO ACEITE</strong></h2>
  <p>
    Esse documento tem função informativa, trazendo alguns conceitos e
    disposições legais, todavia, não se limita a eles.
  </p>
  <p>
    Assim, a pessoa aqui declara expressa concordância na nomeação e aceite do
    encargo de Executor-Guardião do usuário (nome).
  </p>
</div>
